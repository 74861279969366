$(function(){

    var num;

    $('.modal__link').on('click', function() {
        // pos = $(window).scrollTop()+200;
        num = $(this).attr('data-id');
        $('.overlay').show();
        $('.modal__item:nth-child('+num+')').show();
        // $('.modal__item').css('top',pos);
        $('body').addClass('js-modal-open');
        $('html').addClass('js-scrollprevent');
        return false;
    });

    $('.modal .icon--close span, .overlay').on('click', function() {
        $('.overlay').hide();
        $('.modal__item').hide();
        $('body').removeClass('js-modal-open');
        $('html').removeClass('js-scrollprevent');
    });
});
